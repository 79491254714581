<template>
  <div>
    <v-container>
      <base-material-card icon="mdi-clipboard-text" :title="'ข้อมูลพื้นฐาน ' +  user.user_firstname  +'.'+ user.user_lastname " class="px-5 py-3">
        <v-flex class="mb-2">
          <v-card-text>
            <v-list two-line>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-account-circle</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ user.user_director }}</v-list-item-title>
                  <v-list-item-subtitle v-if="user.user_status==='G'">ผู้อำนวยการสำนักงานอาชีวศึกษาจังหวัด{{ user.user_lastname }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="user.user_status==='B'">ผู้อำนวยการ{{ user.user_firstname }}</v-list-item-subtitle>

                  <v-list-item-subtitle v-if="user.user_status==='C'">ผู้อำนวยการ{{ user.user_firstname + user.user_lastname }}</v-list-item-subtitle>
                </v-list-item-content>               
              </v-list-item>

              <v-list-item v-if="user.user_status==='B'">
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-account-circle</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ user.user_deputy_academic }}</v-list-item-title>
                  <v-list-item-subtitle>รองผู้อำนวยการฝ่ายวิชาการ</v-list-item-subtitle>
                </v-list-item-content>  
                
                <v-list-item-content>
                  <v-list-item-title>{{ user.user_deputy_activity }}</v-list-item-title>
                  <v-list-item-subtitle>รองผู้อำนวยการฝ่ายพัฒนากิจการนักเรียนนักศึกษา</v-list-item-subtitle>
                </v-list-item-content>  
              </v-list-item>

              <v-list-item v-if="user.user_status==='B'">
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-account-circle</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ user.user_deputy_resources }}</v-list-item-title>
                  <v-list-item-subtitle>รองผู้อำนวยการฝ่ายบริหารทรัพยากร</v-list-item-subtitle>
                </v-list-item-content>  
                
                <v-list-item-content>
                  <v-list-item-title>{{ user.user_deputy_planning }}</v-list-item-title>
                  <v-list-item-subtitle>รองผู้อำนวยการฝ่ายแผนงานและความร่วมมือ</v-list-item-subtitle>
                </v-list-item-content>  
              </v-list-item>



           

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-phone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ user.user_directorphone }}</v-list-item-title>
                  <v-list-item-subtitle>Mobile</v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>

              <v-list-item>
                <v-list-item-action></v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ user.user_tel_p }}</v-list-item-title>
                  <v-list-item-subtitle>Work</v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>


              <v-list-item>
                <v-list-item-action></v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ user.user_fax }}</v-list-item-title>
                  <v-list-item-subtitle>Fax</v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-email</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ user.user_email }}</v-list-item-title>
                  <v-list-item-subtitle>Work</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

         
              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ user.user_address }}</v-list-item-title>
                  <v-list-item-subtitle>ตำบล {{ user.district_name }} อำเภอ {{ user.prefecture_name }} จังหวัด {{ user.province_name }} รหัสไปรษณีย์  {{ user.post }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

           
            
          </v-card-text>
          <v-card-actions class="pr-2 mb-2">
            <v-spacer></v-spacer>
            <v-btn color="yellow darken-3" dark rounded @click.native="userEditmain()">
              <v-icon small dark>mdi-pencil</v-icon>&nbsp;แก้ไข
            </v-btn>
          </v-card-actions>
        </v-flex>
      </base-material-card>
    </v-container>

    <!-- แก้ไขข้อมูล -->
    <v-layout row justify-center>
      <v-dialog v-model="userdialog1" persistent max-width="80%">
        <v-card class="mx-auto pa-5">
          <base-material-card
            color="yellow"
            icon="mdi-clipboard-text"
            title="แก้ไขข้อมูลพื้นฐาน"
            class="px-5 py-3 text_google"
          ></base-material-card>
          <v-card-text>
            <v-form ref="form1" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>

                  <v-flex xs6>
                    <v-text-field
                      label="ผู้อำนวยการ"
                      v-model="user.user_director"
                      textarea
                      rows="10"
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6>
                    <v-text-field
                      label="เบอร์โทร : ผู้อำนวยการ"
                      v-model="user.user_directorphone"
                      textarea
                      rows="10"
                      outlined
                    ></v-text-field>
                  </v-flex>


                  <v-flex xs6 v-if="user.user_status==='B'" >
                    <v-text-field
                      label="รองผู้อำนวยการฝ่ายวิชาการ"
                      v-model="user.user_deputy_academic"
                      textarea
                      rows="10"
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 v-if="user.user_status==='B'" >
                    <v-text-field
                      label="รองผู้อำนวยการฝ่ายพัฒนากิจการนักเรียนนักศึกษา"
                      v-model="user.user_deputy_activity"
                      textarea
                      rows="10"
                      outlined
                    ></v-text-field>
                  </v-flex>


                  <v-flex xs6 v-if="user.user_status==='B'" >
                    <v-text-field
                      label="รองผู้อำนวยการฝ่ายบริหารทรัพยากร"
                      v-model="user.user_deputy_resources"
                      textarea
                      rows="10"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="user.user_status==='B'" >
                    <v-text-field
                      label="รองผู้อำนวยการฝ่ายแผนงานและความร่วมมือ"
                      v-model="user.user_deputy_planning"
                      textarea
                      rows="10"
                      outlined
                    ></v-text-field>
                  </v-flex>



                  <v-flex xs12>
                    <v-text-field
                      label="ที่อยู่"
                      v-model="user.user_address"
                      textarea
                      rows="10"
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex md4>
                    <v-autocomplete
                      :items="provices"
                      item-text="province_name"
                      item-value="province_ID"
                      label="จังหวัด : "
                      required
                      outlined
                      :rules="[v => !!v || '']"
                      v-model="user.user_province_ID"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex md4>
                    <v-autocomplete
                      :items="prefectures"
                      item-text="prefecture_name"
                      item-value="prefecture_ID"
                      label=" อำเภอ : "
                      required
                      outlined
                      :rules="[v => !!v || '']"
                      v-model="user.user_prefecture_ID"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex md4>
                    <v-autocomplete
                      :items="district"
                      item-text="district_name"
                      item-value="district_ID"
                      label=" ตำบล : "
                      required
                      outlined
                      :rules="[v => !!v || '']"
                      v-model="user.user_district_ID"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-text-field label="โทรศัพท์" v-model="user.user_tel_p" outlined></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field label="โทรสาร" v-model="user.user_fax" outlined></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12>
                    <v-text-field label="E-mail" v-model="user.user_email" outlined></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <small>* จำเป็น</small>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop="userdialog1 = false" rounded>
              <v-icon dark>mdi-close</v-icon>ยกเลิก
            </v-btn>
            <v-btn color="success" @click.stop="userSubmit1()" rounded>
              <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-container fluid>
      <v-snackbar v-model="snackbar.show" top :timeout="snackbar.timeout" :color="snackbar.color">
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
  
  <script>
export default {
  data: () => ({
    valid: true,
    userdialog1: false,
    userdialog2: false,
    userdialog3: false,
    userdialog4: false,
    userdialog5: false,
    deletefiledialog: false,
    deletefiledialog2: false,
    snackbar: {
      show: false,
      color: "",
      timeout: 5000,
      icon: "",
      text: ""
    },
    ApiKey: "HRvec2021",
    user: [],
    period_year: "",
    user_update: {},
    data_syslog: {},
    provices: [],
    prefectures: [],
    district: []
  }),
  async mounted() {
    await this.userQuery();
    let result_provice = await this.$http.post("province_sh.php", {
      ApiKey: this.ApiKey
    });
    this.provices = result_provice.data;
  },

  methods: {
    async userQuery() {
      let result = {};
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        ApiKey: this.ApiKey
      });
      this.user = result.data;
    },

    async userEditmain() {
      this.userdialog1 = true;
    },

    async userEditpic() {
      this.user.ApiKey = this.ApiKey;
      this.userdialog5 = true;
    },

    async userSubmit1() {
      if (this.$refs.form1.validate()) {
        this.user.ApiKey = this.ApiKey;
        let result = await this.$http.post("user.update.php", this.user);
        
        
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.userQuery();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.userQuery();
        }
        this.userdialog1 = false;
      }
    },
    async prefectureQueryAll(province_ID) {
      let result = await this.$http.post("prefecture.php", {
        province_ID: province_ID
      });
      this.prefectures = result.data;
    },

    async districtQueryAll(prefecture_ID) {
      let result = await this.$http.post("districts.php", {
        prefecture_ID: prefecture_ID
      });
      this.district = result.data;
    }
  },
  computed: {
    period_year_bd() {
      let today = new Date();
      let year = today.getFullYear() + 543;
      return year;
    },
    time_stamp() {
      const d = new Date();
      let time = d.getTime();
      return time;
    },

    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    },

    provinceChange() {
      return this.user.user_province_ID;
    },
    prefectureChange() {
      return this.user.user_prefecture_ID;
    }
  },
  watch: {
    async provinceChange() {
      this.prefectureQueryAll(this.user.user_province_ID);
    },
    async prefectureChange() {
      this.districtQueryAll(this.user.user_prefecture_ID);
    },

    async period_year_bd(newVal, oldVal) {
      await this.userQuery();
    }
  }
};
</script>
  </style>
  