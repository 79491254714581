<template>
  <div>
    <UserDetailinfo/>
  </div>
</template>

<script>
import UserDetailinfo from '../../components/userDetailinfo.vue';

export default {
    name: 'HRvecProvincialinfo',
    data() {
        return {};
    },
    mounted() {
    },
    methods: {},
    components: { UserDetailinfo }
};
</script>

<style lang="scss" scoped>

</style>